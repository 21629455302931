import React, { useEffect } from "react";
import styled from "styled-components";
import {
  motion,
  animate,
  useAnimation,
  useSpring,
  useMotionValue,
  Variants
} from "framer-motion";

function useDot() {
  const x = useSpring(0);
  const y = useSpring(0);
  const scale = useSpring(1);
  const controls = useAnimation();

  return { x, y, scale, controls } as const;
}

const size = 65;

function Playground() {
  const {
    x: blueX,
    y: blueY,
    scale: blueScale,
    controls: blueControls
  } = useDot();
  const {
    x: purpleX,
    y: purpleY,
    scale: purpleScale,
    controls: purpleControls
  } = useDot();
  const {
    x: pinkX,
    y: pinkY,
    scale: pinkScale,
    controls: pinkControls
  } = useDot();
  const {
    x: orangeX,
    y: orangeY,
    scale: orangeScale,
    controls: orangeControls
  } = useDot();

  const blue = { x: blueX, y: blueY, scale: blueScale };
  const purple = { x: purpleX, y: purpleY, scale: purpleScale };
  const pink = { x: pinkX, y: pinkY, scale: pinkScale };
  const orange = { x: orangeX, y: orangeY, scale: orangeScale };

  const colors = [blue, pink, orange];
  const controls = [blueControls, pinkControls, orangeControls];
  const rotate = useMotionValue(0);

  function handlePause() {}

  async function handleIdle() {
    animate(rotate, 360, { ease: "linear", repeat: Infinity, duration: 2 });
    animate(purple.scale, 1.5);
    animate(purple.x, size / 3.47);
    animate(purple.y, size / 3.47);

    animate(pink.scale, 0.7);
    animate(pink.x, size / 10.42);
    animate(pink.y, size / 1.97);

    animate(blue.scale, 1.05);
    animate(blue.x, size / 1.97);
    animate(blue.y, size / 1.87);

    animate(orange.scale, 0.9);
    animate(orange.x, size / 9.1);
    animate(orange.y, size / 24.3);
  }

  async function handleStart() {
    animate(rotate, 0);
    animate(pink.x, size / 73);
    animate(pink.y, size / 2.92);
    animate(pink.scale, 1);

    animate(orange.x, size / 3.84);
    animate(orange.y, size / 2.92);
    animate(orange.scale, 1);

    animate(purple.x, size / 1.97);
    animate(purple.y, size / 2.92);
    animate(purple.scale, 1);

    animate(blue.scale, 1);
    animate(blue.x, size / 1.32);
    animate(blue.y, size / 2.92);

    // handlePulse();
  }

  function handlePulse() {
    animate(pink.scale, 1.5);
    animate(orange.scale, 0.5);
    animate(purple.scale, 0.5);
    animate(blue.scale, 0.5);

    animate(pink.scale, 0.5);
    animate(orange.scale, 1.5);
    animate(purple.scale, 0.5);
    animate(blue.scale, 0.5);

    animate(pink.scale, 0.5);
    animate(orange.scale, 0.5);
    animate(purple.scale, 1.5);
    animate(blue.scale, 0.5);

    animate(pink.scale, 0.5);
    animate(orange.scale, 0.5);
    animate(purple.scale, 0.5);
    animate(blue.scale, 1.5);
  }

  function handleReset() {
    handleIdle();
  }

  useEffect(() => {
    handleStart();
  }, []);

  return (
    <Wrapper>
      <DotsContainer
        style={{
          rotate,
          width: size,
          height: size / 1.2,
          // @ts-ignore
          ["--base-size"]: `${size / 4.3}px`
        }}
      >
        <PurpleDot style={purple} animate={purpleControls} />
        <PinkDot style={pink} animate={pinkControls} />
        <BlueDot style={blue} animate={blueControls} />
        <OrangeDot style={orange} animate={orangeControls} />
      </DotsContainer>
      <ButtonsContainer>
        <Button onClick={handlePause}>pause</Button>
        <Button onClick={handleStart}>start</Button>
        <Button onClick={handleReset}>reset</Button>
      </ButtonsContainer>
    </Wrapper>
  );
}

export default Playground;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
`;

const DotsContainer = styled(motion.div)`
  /* --base-size: 84px; */
  --purple-size: calc(var(--base-size) * 1.85);
  --pink-size: calc(var(--base-size) * 0.75);
  --blue: linear-gradient(50.66deg, #7ecefd 20.28%, #91c2ff 94.25%);
  --purple: linear-gradient(140.66deg, #b3b3ff 18.64%, #d9a1f2 92.61%);
  --pink: linear-gradient(230.66deg, #f78dd3 5.75%, #ff7da9 79.72%);
  --orange: linear-gradient(320.66deg, #ff777a 7.39%, #fc7d49 81.36%);

  position: relative;
`;

const Dot = styled(motion.div)`
  width: var(--base-size);
  height: var(--base-size);
  border-radius: 50%;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
`;

const BlueDot = styled(Dot)`
  background: var(--blue);
`;

const PurpleDot = styled(Dot)`
  background: var(--purple);
  z-index: 1;
`;

const PinkDot = styled(Dot)`
  background: var(--pink);
`;

const OrangeDot = styled(Dot)`
  background: var(--orange);
`;

const ButtonsContainer = styled.div`
  position: fixed;
  top: 20%;
  right: 15%;
  display: flex;
  gap: 1rem;
`;

const Button = styled.button`
  background: none;
  outline: none;
  border: none;
  font-size: 1.6rem;
  padding: 0.5rem 2rem;
  border-radius: 9999px;
  background: linear-gradient(to right, cornflowerblue, slateblue);
  color: white;
  cursor: pointer;
`;
